import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";

const SelectFormik = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field] = useField(props);
  return (
    <div className="mt-3">
      <label htmlFor={field.name}>{label}</label>
      <select className="formik-input" {...field} {...props} />
    </div>
  );
};

SelectFormik.propTypes = {
  label: PropTypes.any,
};

export default SelectFormik;
