import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";

const CheckBox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <label className="checkbox">
        <input
          {...field}
          {...props}
          type="checkbox"
          className="form-check-input"
        />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

CheckBox.propTypes = {
  children: PropTypes.any,
};

export default CheckBox;
