import { gql } from "apollo-boost";

export const LOGIN_ADMIN = gql`
  mutation login($username: String!, $password: String!, $rememberMe: Boolean) {
    login(username: $username, password: $password, rememberMe: $rememberMe) {
      ... on CurrentUser {
        id
        identifier
      }
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`;

export const ACTIVE_ADMIN = gql`
  {
    activeAdministrator {
      id
      firstName
      lastName
      emailAddress
      user {
        lastLogin
        verified
      }
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation updateActiveAdministrator($password: String) {
    updateActiveAdministrator(input: { password: $password }) {
      id
    }
  }
`;

export const LOGOUT_ADMIN = gql`
  mutation {
    logout {
      success
    }
  }
`;
