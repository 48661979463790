import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "./Button";

const Error = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
    navigate(0);
  };

  return (
    <div className="col-6 mx-auto mt-5">
      <div className="alert alert-danger" role="alert">
        An error occurred!
      </div>
      <Button title={"Go Back"} onClick={() => goBack()} />
    </div>
  );
};

export default Error;
