import React from "react";
import { Outlet } from "react-router-dom";

import { Sidebar, DashboardNav } from "../components";

const Dashboard = () => {
  return (
    <>
      <div className="oja-dashboard">
        <div className="oja-dashboard-navbar">
          <DashboardNav />
        </div>
        <div>
          <div className="oja-dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="oja-dashboard-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
