import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { TextField, Button } from "../../components";
import { AuthLayout } from "../../layouts";

const ForgotPassword = () => {
  const initialValues = {
    email: "",
  };

  const validate = Yup.object({
    email: Yup.string()
      .email("Email is not invalid")
      .required("Email is required"),
  });

  return (
    <>
      <AuthLayout src="../../../images/auth2.png" alt="auth_image">
        <div className="text-center mt-5">
          <h1 className="title mb-4">
            Forget your password? <br /> Get a reset link.
          </h1>

          <p className="col-8 mx-auto oja-forgot-pwd-p">
            With a reset password link, you can choose a new password and login
            to your account. Enter your email address to begin.
          </p>
        </div>
        <div className="login-form">
          <Formik initialValues={initialValues} validationSchema={validate}>
            {() => (
              <Form>
                <TextField
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                />

                <div>
                  <Button title="Send Link" className={"login-btn"} />
                </div>

                <div className="text-center mt-4">
                  <p className="oja-forgot-pwd-p">
                    Remember your password?{" "}
                    <Link to="/" className="oja-forgot-pwd-a">
                      Login
                    </Link>{" "}
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default ForgotPassword;
