import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const SidebarItem = ({ item, roles }) => {
  const [open, setOpen] = useState(false);

  const hasRoles = (userRoles, pageRoles) => {
    for (const role of pageRoles) {
      if (userRoles?.includes(role)) return true;
    }
    return false;
  };

  const navLinkStyles = ({ isActive }) => {
    return {
      background: isActive ? "#6E449B" : "",
      color: isActive ? "#fff" : "",
    };
  };

  if (item.subRoutes) {
    return (
      <div>
        <div className={open ? "sidebar-item open" : "sidebar-item"}>
          <div className="sidebar-title">
            <img src={item.icon} alt="" />
            <span>{item.name}</span>
            <i className="toggle-btn" onClick={() => setOpen(!open)}>
              <img src="../../../svgs/down-arrow.svg" alt="down-arrow" />
            </i>
          </div>
        </div>
        <div className="sidebar-content">
          {item.subRoutes.map((child) =>
            roles?.includes("__super_admin_role__") ||
            (child.role.length && hasRoles(roles, child.role)) ? (
              <SidebarItem key={child.id} item={child} />
            ) : (
              ""
            )
          )}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        style={navLinkStyles}
        to={item.path}
        className="sidebar-item plain"
      >
        <img src={item.icon} alt="" className="img-fluid" />
        <span>{item.name}</span>
      </NavLink>
    );
  }
};

SidebarItem.propTypes = {
  item: PropTypes.any,
  roles: PropTypes.any,
};

export default SidebarItem;
