import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import client from "./graphql/apolloClient";
import { AuthProvider } from "./context/AuthContext";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/responsiveness.css";
import "./styles/global.css";
import "./styles/auth.css";
import "./styles/dashboard.css";

Sentry.init({
  // eslint-disable-next-line no-undef
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
