import PropTypes from "prop-types";

const Button = ({ title, className, disabled, onClick }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type="submit"
      className={`btn w-100 ${className}`}
    >
      {title}
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
