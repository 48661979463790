import React, { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthContext } from "../../context/AuthContext";
import { LOGOUT_ADMIN } from "../../graphql/queries/authQueries";
import { ADMINS } from "../../graphql/queries/customerRepQueries";
import { dashboardLinksArray } from "../../utils/static";
import SidebarItem from "./SidebarItem";

const Sidebar = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const [logoutAdmin] = useMutation(LOGOUT_ADMIN);
  const { data } = useQuery(ADMINS);

  const roles = data?.activeAdministrator?.user.roles.map((val) => val.code);

  const logout = () => {
    logoutAdmin();
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
    toast.success("Logout successful");
  };

  return (
    <aside className="oja-sidebar">
      <div className="oja-sidebar-container">
        {dashboardLinksArray.map((item) =>
          roles?.includes("__super_admin_role__") ||
          !item.role ||
          (item.role !== "admin_only" && roles?.includes(item.role)) ? (
            <SidebarItem key={item.id} item={item} roles={roles} />
          ) : (
            ""
          )
        )}

        <Link
          onClick={() => logout()}
          to="#"
          className="sidebar-item plain logout"
        >
          <img src="/svgs/logout.svg" alt="" />
          <span>Logout</span>
        </Link>
      </div>
    </aside>
  );
};

export default Sidebar;
