import React, { useState } from "react";
// import { AuthLayout } from "../../layouts";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { TextField, DashboardHeader, ButtonSpinner } from "../../components";
import {
  ACTIVE_ADMIN,
  PASSWORD_RESET,
} from "../../graphql/queries/authQueries";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { data } = useQuery(ACTIVE_ADMIN);

  const initialValues = {
    id: data?.activeAdministrator.id,
    password: "",
  };

  const validate = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  const [updateAdministrator, { loading }] = useMutation(PASSWORD_RESET, {
    update() {
      // Success message and close modal
      toast.success("Password reset successfully!");
      navigate("/");
    },
    // Set values for backend
    variables: {
      id: data?.activeAdministrator.id,
      password: "",
    },
    // Error
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);

      if (errors) {
        toast.error("An error occured!");
      }
    },
  });

  const handleSubmit = (values) => {
    updateAdministrator({ variables: values });
  };

  return (
    <>
      <DashboardHeader title="Reset Password" />
      <div className="reset-form mx-auto mt-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={(values) => handleSubmit(values)}
        >
          {() => (
            <Form>
              <TextField
                label="New Password"
                name="password"
                type="password"
                placeholder="Enter a new password"
              />
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                placeholder="Confirm your new password"
              />

              <div>
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    border: "none",
                    height: "44px",
                  }}
                >
                  {loading ? <ButtonSpinner /> : "Change Password"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ResetPassword;
