import { gql } from "apollo-boost";

// queries and mutation for customer rep
export const ADD_CUSTOMER_REP = gql`
  mutation createCustomerRepresentative(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $roleIds: [ID!]
  ) {
    createCustomerRepresentative(
      input: {
        firstName: $firstName
        lastName: $lastName
        emailAddress: $email
        password: $password
        roleIds: $roleIds
      }
    ) {
      id
      firstName
    }
  }
`;

export const ROLES = gql`
  query {
    customerRepresentativeRoles {
      id
      code
      description
    }
  }
`;

export const UPDATE_CUSTOMER_REP = gql`
  mutation updateAdministrator(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $roleIds: [ID!]
  ) {
    updateAdministrator(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
        roleIds: $roleIds
      }
    ) {
      id
    }
  }
`;

export const ADMINS = gql`
  query {
    activeAdministrator {
      user {
        roles {
          id
          code
          description
        }
      }
    }
  }
`;

export const GET_CUSTOMER_REP = gql`
  query {
    customerRepresentatives {
      items {
        id
        firstName
        lastName
        emailAddress
        user {
          roles {
            id
            code
            description
          }
        }
      }
    }
  }
`;

export const GET_ACTIVE_ADMINS = gql`
  query {
    administrators {
      items {
        firstName
        lastName
        emailAddress
        id
        user {
          roles {
            id
            code
            description
          }
        }
        __typename
      }
    }
  }
`;

export const DELETE_ACTIVE_ADMINS = gql`
  mutation deleteAdministrator($id: ID!) {
    deleteAdministrator(id: $id) {
      result
      message
    }
  }
`;
