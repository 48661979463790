import React from "react";
// import { AiFillDelete } from "react-icons/ai";

// import { notifications } from "../utils/static";
// import NotificationList from "./NotificationList";

const DashboardNav = () => {
  // const [show, setShow] = useState(false);
  // const [contacts, setContacts] = useState(notifications);

  // const handleDelete = (index) => {
  //   const newContacts = [...contacts];

  //   const i = contacts.findIndex((contact) => contact.id === index);

  //   newContacts.splice(i, 1);

  //   setContacts(newContacts);
  // };

  // const showNotifications = () => setShow(!show);

  return (
    <nav className="oja-nav-dashboard">
      <div className="d-flex justify-content-start">
        <div className="oja-nav-logo">
          <img src="/svgs/logo.svg" alt="Logo" width="40%" />
        </div>

        <div className="oja-nav-form">
          <form className="form-inline mx-4">
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
          </form>
        </div>

        {/* <div className="pt-2 oja-img-item">
          <img src="../../../svgs/profile.svg" alt="profile" />
          <button
            data-count={contacts.length}
            onClick={() => showNotifications()}
          >
            <img
              src="../../../svgs/notification.svg"
              alt="notification"
              className="oja-notification-img"
            />
          </button>
          {show && (
            <div className="oja-notification">
              <div className="card">
                <div className="card-body">
                  <p className="fw-bold txt-color">Notifications</p>
                  <hr />
                  {contacts.map((value) => (
                    <div key={value.id}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <NotificationList value={value} />
                        </div>
                        <button
                          type="submit"
                          className="clear"
                          onClick={() => handleDelete(value.id)}
                        >
                          <AiFillDelete />
                        </button>
                      </div>
                      <hr />
                    </div>
                  ))}
                  {contacts.length === 0 && (
                    <div className="text-center mt-3">
                      <p>There are no new notification(s)</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </nav>
  );
};

export default DashboardNav;
