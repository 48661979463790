import React, { useState } from "react";
import { PropTypes } from "prop-types";

const Search = ({ onSearch }) => {
  const [search, setSearch] = useState("");

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };
  return (
    <div>
      <input
        type="search"
        className="form-control"
        style={{ width: "240px" }}
        placeholder={search || "Search"}
        aria-label="Search"
        onChange={(e) => onInputChange(e.target.value)}
      />
    </div>
  );
};

Search.propTypes = {
  onSearch: PropTypes.any,
};

export default Search;
