import React from "react";

import { SuccessLayout } from "../../layouts";

const LinkSent = () => {
  return (
    <>
      <SuccessLayout src="../../../images/auth2.png" alt="auth_image">
        <div className="mt-4">
          <h1>Link Sent!</h1>

          <p className="col-8 mx-auto mt-5">
            A password reset link has been sent to your email. Please check your
            email to change your password
          </p>
        </div>
      </SuccessLayout>
    </>
  );
};

export default LinkSent;
