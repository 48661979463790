import React from "react";

import { SuccessLayout } from "../../layouts";

const PasswordChanged = () => {
  return (
    <>
      <SuccessLayout src="../../../images/auth3.png" alt="auth_image">
        <div className="mt-4">
          <h1>Password Successfully Changed!</h1>

          <p className="col-8 mx-auto mt-5">
            Your password has been changed successfully, please proceed to
            login.
          </p>
        </div>
      </SuccessLayout>
    </>
  );
};

export default PasswordChanged;
