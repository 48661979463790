export const dashboardLinksArray = [
  {
    id: 1,
    icon: "../../../svgs/dashboard.svg",
    name: "Dashboard",
    path: "home",
    role: ["admin_only"],
  },
  {
    id: 2,
    icon: "../../../svgs/bookmark.svg",
    name: "Management",
    path: "/dashboard/management",
    role: "",
    subRoutes: [
      {
        id: 1,
        icon: "../../../svgs/customer.svg",
        name: "Customers",
        path: "customers",
        role: [
          "__customer_create__",
          "__customer_read__",
          "__customer_update__",
          "__customer_delete__",
        ],
      },
      {
        id: 2,
        icon: "../../../svgs/rep.svg",
        name: "Customer Rep",
        path: "customer-reps",
        role: [],
      },
      {
        id: 3,
        icon: "../../../svgs/rider.svg",
        name: "Riders",
        path: "riders",
        role: [
          "__rider_create__",
          "__rider_read__",
          "__rider_update__",
          "__rider_delete__",
        ],
      },
      {
        id: 4,
        icon: "../../../svgs/order.svg",
        name: "Orders",
        path: "orders",
        role: [
          "__order_create__",
          "__order_read__",
          "__order_update__",
          "__order_delete__",
        ],
      },
      {
        id: 5,
        icon: "../../../svgs/category.svg",
        name: "Categories",
        path: "categories",
        role: [
          "__collection_create__",
          "__collection_read__",
          "__collection_update__",
          "__collection_delete__",
        ],
      },
      {
        id: 6,
        icon: "../../../svgs/products.svg",
        name: "Products",
        path: "products",
        role: [
          "__product_create__",
          "__product_read__",
          "__product_update__",
          "__product_delete__",
        ],
      },
      {
        id: 7,
        icon: "../../../svgs/banner.svg",
        name: "Banners",
        path: "banners",
        role: [
          "__banner_create__",
          "__banner_read__",
          "__banner_update__",
          "__banner_delete__",
        ],
      },
      {
        id: 8,
        icon: "../../../svgs/delivery.svg",
        name: "Delivery/Shop",
        path: "shipping",
        role: [],
      },
      // {
      //   id: 10,
      //   name: "Testimonials",
      //   path: "testimonials",
      //   role: [],
      // },
      {
        id: 11,
        icon: "../../../svgs/coupon.svg",
        name: "Coupons",
        path: "coupons",
        role: [
          "__promotion_create__",
          "__promotion_read__",
          "__promotion_update__",
          "__promotion_delete__",
        ],
      },
      {
        id: 12,
        icon: "../../../svgs/transaction.svg",
        name: "Transactions",
        path: "transactions",
        role: [],
      },
      {
        id: 13,
        icon: "../../../svgs/transaction.svg",
        name: "Collectibles",
        path: "collectibles",
        role: [],
      },
    ],
  },
  {
    id: 3,
    icon: "../../../svgs/activity.svg",
    name: "Data Analytics",
    path: "data-analytics",
    role: ["admin_only"],
  },
  {
    id: 4,
    icon: "../../../svgs/setting.svg",
    name: "Settings",
    path: "settings",
    // role: ["admin_only"],
  },
];
