import React, { Suspense, useContext } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";

import { Spinner, Error } from "./components";

import { AuthContext } from "./context/AuthContext";

// Pages
// Auth
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import LinkSent from "./pages/auth/LinkSent";
import PasswordChanged from "./pages/auth/PasswordChanged";
import ResetPassword from "./pages/auth/ResetPassword";

// Admin Dashboard
import Dashboard from "./layouts/Dashboard";
const Home = React.lazy(() => import("./pages/dashboard/admin/Home"));
// Customer Dashboard
const Customer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/Customer")
);
const AddCustomer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/components/AddCustomer")
);
const EditCustomer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/components/EditCustomer")
);
const ViewCustomer = React.lazy(() =>
  import("./pages/dashboard/admin/customer/components/ViewCustomer")
);
// Rider Dashboard
const Rider = React.lazy(() => import("./pages/dashboard/admin/rider/Rider"));
const AddRider = React.lazy(() =>
  import("./pages/dashboard/admin/rider/components/AddRider")
);
const EditRider = React.lazy(() =>
  import("./pages/dashboard/admin/rider/components/EditRider")
);
const RiderDetails = React.lazy(() =>
  import("./pages/dashboard/admin/rider/components/RiderDetails")
);
// Customer Reps
const CustomerRep = React.lazy(() =>
  import("./pages/dashboard/admin/customerReps/CustomerRep")
);
const AddCustomerRep = React.lazy(() =>
  import("./pages/dashboard/admin/customerReps/components/AddCustomerRep")
);
// Order
const Order = React.lazy(() => import("./pages/dashboard/admin/order/Order"));
const OrderDetals = React.lazy(() =>
  import("./pages/dashboard/admin/order/components/OrderDetails")
);
// Category
const Category = React.lazy(() =>
  import("./pages/dashboard/admin/categories/Category")
);
const SubCategories = React.lazy(() =>
  import("./pages/dashboard/admin/categories/SubCategories")
);
const EditCategory = React.lazy(() =>
  import("./pages/dashboard/admin/categories/components/EditCategory")
);
// Product
const Product = React.lazy(() =>
  import("./pages/dashboard/admin/product/Product")
);
const ViewProduct = React.lazy(() =>
  import("./pages/dashboard/admin/product/components/ViewProduct")
);
const EditProduct = React.lazy(() =>
  import("./pages/dashboard/admin/product/components/EditProduct")
);
// Transactions
const Transactions = React.lazy(() =>
  import("./pages/dashboard/admin/transactions/Transactions")
);
// Settings
const Settings = React.lazy(() =>
  import("./pages/dashboard/admin/settings/Settings")
);
// Data Analytics
const DataAnalytics = React.lazy(() =>
  import("./pages/dashboard/admin/data/DataAnalytics")
);
// Banners
const Banners = React.lazy(() =>
  import("./pages/dashboard/admin/banners/Banners")
);
const ViewBanner = React.lazy(() =>
  import("./pages/dashboard/admin/banners/components/BannerDetails")
);
const UpdateBannerImage = React.lazy(() =>
  import("./pages/dashboard/admin/banners/components/UpdateImage")
);
// Shiping
const Shipping = React.lazy(() =>
  import("./pages/dashboard/admin/shipping/Shipping")
);
// Reviews
const Review = React.lazy(() =>
  import("./pages/dashboard/admin/rating/Rating")
);
// Testimonials
const Testimonial = React.lazy(() =>
  import("./pages/dashboard/admin/testimonial/Testimonial")
);
const AddTestimonial = React.lazy(() =>
  import("./pages/dashboard/admin/testimonial/components/AddTestimonial")
);
const EditTestimonial = React.lazy(() =>
  import("./pages/dashboard/admin/testimonial/components/EditTestimonial")
);

const Coupon = React.lazy(() =>
  import("./pages/dashboard/admin/coupon/Coupon")
);
const ViewCoupon = React.lazy(() =>
  import("./pages/dashboard/admin/coupon/components/ViewCoupon")
);

const Collectibles = React.lazy(() =>
  import("./pages/dashboard/admin/collectibles/Collectibles")
);

import AppLogout from "./components/AppLogout";

import NotFound from "./pages/NotFound";

const App = () => {
  const { currentUser } = useContext(AuthContext);

  // eslint-disable-next-line react/prop-types
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };

  return (
    <>
      <ErrorBoundary fallback={<Error />}>
        <ToastContainer autoClose={5000} />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/">
              <Route index element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="link-sent" element={<LinkSent />} />
              <Route path="password-changed" element={<PasswordChanged />} />

              {/* Admin dashboard links */}
              <Route
                path="dashboard"
                element={
                  <AppLogout>
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  </AppLogout>
                }
              >
                <Route path="home" element={<Home />} />
                <Route path="password-reset" element={<ResetPassword />} />

                {/* customers */}
                <Route path="customers" element={<Customer />} />
                <Route
                  path="customers/view-customer/:customerId"
                  element={<ViewCustomer />}
                />
                <Route
                  path="customers/add-customer"
                  element={<AddCustomer />}
                />
                <Route
                  path="customers/edit-customer/:customerId"
                  element={<EditCustomer />}
                />

                {/* customer reps */}
                <Route path="customer-reps" element={<CustomerRep />} />
                <Route
                  path="customer-reps/add-customer-rep"
                  element={<AddCustomerRep />}
                />

                {/* riders */}
                <Route path="riders" element={<Rider />} />
                <Route path="riders/add-rider" element={<AddRider />} />
                <Route
                  path="riders/edit-rider/:riderId"
                  element={<EditRider />}
                />
                <Route
                  path="riders/view-rider/:riderId"
                  element={<RiderDetails />}
                />

                {/* order */}
                <Route path="orders" element={<Order />} />
                <Route
                  path="orders/order-details/:orderId"
                  element={<OrderDetals />}
                />

                {/* category */}
                <Route path="categories" element={<Category />} />
                <Route
                  path="categories/:categoryId"
                  element={<SubCategories />}
                />
                <Route
                  path="categories/edit-category/:categoryId"
                  element={<EditCategory />}
                />

                {/* product */}
                <Route path="products" element={<Product />} />
                <Route
                  path="products/product-details/:productId"
                  element={<ViewProduct />}
                />
                <Route
                  path="products/edit-product/:productId"
                  element={<EditProduct />}
                />

                {/* banners */}
                <Route path="banners" element={<Banners />} />
                <Route
                  path="banners/banner-details/:bannerId"
                  element={<ViewBanner />}
                />
                <Route
                  path="banners/update-image/:imageId"
                  element={<UpdateBannerImage />}
                />

                {/* shipping */}
                <Route path="shipping" element={<Shipping />} />

                {/* review */}
                <Route path="reviews" element={<Review />} />

                {/* testimonial */}
                <Route path="testimonials" element={<Testimonial />} />
                <Route
                  path="testimonials/add-testimonial"
                  element={<AddTestimonial />}
                />
                <Route
                  path="testimonials/edit/:testimonialId"
                  element={<EditTestimonial />}
                />

                {/* coupon */}
                <Route path="coupons" element={<Coupon />} />
                <Route path="coupons/:couponId" element={<ViewCoupon />} />

                {/* transactions */}
                <Route path="transactions" element={<Transactions />} />

                {/* data */}
                <Route path="data-analytics" element={<DataAnalytics />} />

                {/* settings */}
                <Route path="settings" element={<Settings />} />

                {/* settings */}
                {/* collectibles */}
                <Route path="collectibles" element={<Collectibles />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Outlet />
      </ErrorBoundary>
    </>
  );
};

export default Sentry.withProfiler(App);
