import PropTypes from "prop-types";

import { Button } from "../components";

const Success = (props) => {
  const { src, alt, children } = props;
  return (
    <div className="oja-auth-layout-container">
      <div className="login-left d-none d-lg-block">
        <img src={src} alt={alt} />
      </div>

      <div className="login-right">
        <div className="oja-auth-bg">
          <div className="card">
            <div className="card-body">
              <div className="text-center mb-5">
                <img src="/svgs/logo.svg" alt="logo" width="40%" />
              </div>

              <div className="oja-success-content text-center">
                <div>
                  <img src="../../../svgs/greencheck.svg" alt="success" />
                </div>
                {children}
              </div>

              <div>
                <Button title="Continue" className={"login-btn"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Success.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default Success;
