import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import { AuthContext } from "../../context/AuthContext";
import { AuthLayout } from "../../layouts";
import { TextField, Button, ButtonSpinner } from "../../components";
import { LOGIN_ADMIN } from "../../graphql/queries/authQueries";

const Login = () => {
  // React hooks
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  // Function to toggle show password
  const togglePassword = () => setShow(!show);

  // Formik initial values
  const initialValues = {
    username: "",
    password: "",
  };

  // Formik validation schema
  const validate = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string()
      .min(8, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  useEffect(() => {}, []);

  // Graphql queries and fuction to login admin
  const [loginAdmin, { loading }] = useMutation(LOGIN_ADMIN, {
    update(_, result) {
      // Security check and navigation
      if (result.data.login.__typename === "InvalidCredentialsError") {
        toast.error("The provided credentials are invalid");
      }
      if (result.data.login.__typename === "CurrentUser") {
        dispatch({ type: "LOGIN", payload: result.data.login.identifier });

        navigate("/dashboard/home");
        toast.success("Login successful");
      }
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);

      if (errors) {
        toast.error("An error occured!");
      }
    },
    // Set values for backend
    variables: initialValues,
  });

  // Fucntion to handle submit button
  const handleSubmit = (values) => {
    loginAdmin({ variables: values });
  };

  return (
    <>
      <AuthLayout src="../../../images/auth1.png" alt="auth_image">
        <h1 className="title mb-4">Welcome back! Please login</h1>
        <div className="login-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={(values) => handleSubmit(values)}
          >
            {() => (
              <Form>
                <TextField
                  label="User Name"
                  name="username"
                  type="text"
                  placeholder="Enter your Username"
                />
                <TextField
                  label="Password"
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="Enter your password"
                  passwordEye={true}
                  togglePassword={togglePassword}
                />

                <div className="d-flex justify-content-between">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlInline"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customControlInline"
                    >
                      Stay logged in
                    </label>
                  </div>

                  {/* <Link to="/password-reset">
                    <p className="oja-forgot-pwd-a">Reset password</p>
                  </Link> */}
                </div>

                <div>
                  <Button
                    disabled={loading}
                    title={loading ? <ButtonSpinner /> : "Login"}
                    className={"login-btn"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default Login;
