import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const DashboardHeader = ({
  title,
  buttonText,
  buttonPath,
  showAdd,
  buttonClick,
}) => {
  return (
    <div className="oja-dashboard-header">
      <div className="heading">
        <h1>{title ? title : ""}</h1>

        <div className="row mx-3">
          {showAdd && (
            <Link to={buttonPath ? buttonPath : ""} className="col-4">
              <button onClick={buttonClick} type="button">
                <img
                  className="mx-2"
                  src="../../../svgs/add-contact.svg"
                  alt="add"
                />
                {buttonText ? buttonText : "Add Text"}
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

DashboardHeader.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonPath: PropTypes.string,
  showAdd: PropTypes.bool,
  buttonClick: PropTypes.func,
};

export default DashboardHeader;
